
function FirstLoader() {

return  (

<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', margin: 0, color: 'var(--brand)'}}>
<i class="fa-solid fa-wand-magic-sparkles fa-bounce fa-4x"></i>

</div>






)
};

export default FirstLoader;